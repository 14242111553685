import React, { FC } from "react";
import Title from "../Title";
import profileImage from "../../images/profile.jpeg";
import "./About.scss";

const stack = [
  {
    id: 0,
    title: "Python",
  },
  {
    id: 1,
    title: "Node.js",
  },
  {
    id: 2,
    title: "React",
  },
  {
    id: 3,
    title: "Vue",
  },
  {
    id: 4,
    title: "Jest",
  },
  {
    id: 5,
    title: "Javascript",
  },
  {
    id: 6,
    title: "Typescript",
  },
  {
    id: 7,
    title: "HTML",
  },
  {
    id: 8,
    title: "CSS/SASS",
  },
  {
    id: 9,
    title: "MongoDB",
  },
  {
    id: 10,
    title: "Rest-apis",
  },
  {
    id: 11,
    title: "GraphQl",
  },
  {
    id: 12,
    title: "Firebase",
  },
  {
    id: 13,
    title: "C++",
  },
  {
    id: 14,
    title: "Swift",
  },
  {
    id: 15,
    title: "SpriteKit",
  },
  {
    id: 16,
    title: "Solidity",
  },
  {
    id: 17,
    title: "git",
  },
];

const About: FC = () => {
  return (
    <section className="about-page">
      <div className="section-center about-center">
        <img className="about-img" src={profileImage} alt="profile" />
        <article className="about-text">
          <Title title={"About Me:"}></Title>
          <p>
            I am a Software Engineer with a strong background in mathematics. Lately, I worked as a developer and project manager for the Munich-based fintech SecCrest Technology while developing an AI trading algorithm for my master thesis. <br></br> 
            Previously I gained experience as a Software Engineer supporting the Tech-Team at the digital agency DieProduktMacher with building various applications like customer-portals, websites or chatbots.<br></br>
            Since 4 years I have been working as a freelance Software Engineer implementing numerous web projects, online marketing campaigns and SEO. 
            Currently, I am also involved in blockchain development, NFTs and other crypto applications.
            I love getting into new programming languages and frameworks and I am always looking for new challenges.
          </p>
          <div className="about-stack">
            <h3>Tech Stack:</h3>
            {stack.map((item) => {
              return <span key={item.id}>{item.title}</span>;
            })}
          </div>
        </article>
      </div>
    </section>
  );
};

export default About;
